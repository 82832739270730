import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { getTime2, hideScanner, showScanner } from "../../common/helpers";
import { Button, Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import moment from "moment";
import { Link, useLocation, useParams } from "react-router-dom";
import "./operator-scan.css";

/**
 * flutter handler menggunakan window.flutter_inappwebview
 * Message Channel menggunakan variable window.port
 */

let headers;
const CustomAlert = withReactContent(Swal);
const Toast = CustomAlert.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
});
var lastScannedTicket;
var timerClearLastScannedTicket;

/**
 * Request histories scan user
 * @returns []
 */
const getScanLogs = async (eventId, exclusive = "") => {
    headers = {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("operator_token"),
    };
    let result = [];
    try {
        let params = {
            church_event_id: eventId,
        };
        if (exclusive) {
            params.exclusive = exclusive;
        }
        let req = await axios({
            method: "GET",
            url: `${process.env.REACT_APP_API2_URL}/me/scan/histories`,
            params,
            headers,
        });
        // isi ke variable hanya jika ada isinya saja
        if (req.data && req.data.results) {
            result = req.data.results;
        }
    } catch (exc) {
        console.error("Error loading scan histories");
        console.error(exc);
    }
    return result;
};

function OperatorScan() {
    const [event, setEvent] = useState({});
    const [data, setData] = useState(null);
    const [isScan, setIsScan] = useState(false);
    const [ticket, setTicket] = useState(0);
    const [alertShowModal, setAlertShowModal] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [scanLogs, setScanLogs] = useState([]);
    const params = useParams();
    const { search } = useLocation();
    const query = React.useMemo(() => new URLSearchParams(search), [search]);
    const isRereg = query.get("rereg") === "1" ? true : false;
    const exclusive = query.get("exclusive") || "";
    const renderHistory = (log, i) => {
        return (
            <div
                className="log-card"
                style={{
                    fontSize: "0.8em",
                }}
                key={`log-${i}`}
            >
                <div
                    className="log-card-flag"
                    style={{ backgroundColor: `#${log.ticket_color_hex}` }}
                ></div>
                {`${moment(log.created_at).format("HH:mm")}: ${log.session} - ${
                    log.quantity
                } - ${log.name}`}
            </div>
        );
    };

    const renderHistories = () => {
        return (
            <div className="container">
                <div className="mb-1">
                    <b>History Scan</b>
                </div>
                <div className="history-wrapper">
                    {scanLogs &&
                        scanLogs.map((log, i) => {
                            return renderHistory(log, i);
                        })}
                </div>
            </div>
        );
    };

    const renderExtras = (extras) => {
        if (!extras) {
            return <></>;
        }
        extras = JSON.parse(extras);
        let s = "";
        for (let o in extras) {
            if (s !== "") {
                s += ", ";
            }
            s += `${o.replace(/(<([^>]+)>)/gi, "")}: <b>${String(
                extras[o]
            ).replace(/(<([^>]+)>)/gi, "")}</b>`;
        }
        return (
            <div style={{ marginLeft: "20px", marginRight: "20px" }}>
                Catatan:
                <br />
                <span dangerouslySetInnerHTML={{ __html: s }}></span>
            </div>
        );
    };

    const renderPaidStatus = (paidStatus) => {
        if (paidStatus === "PAID") {
            return <span className="badge badge-success">{paidStatus}</span>;
        } else {
            return <span className="badge badge-secondary">{paidStatus}</span>;
        }
    };

    const renderActions = () => {
        return (
            <div className="container text-right mt-4">
                <Link
                    to={{
                        pathname: "/operator/events",
                        state: {
                            lastEventCode: params.code,
                        },
                    }}
                    className="btn btn-outline-danger"
                    replace={true}
                >
                    Back
                </Link>
            </div>
        );
    };

    const handleScan = useCallback(
        (scanValue) => {
            if (!isScan && scanValue && scanValue !== lastScannedTicket) {
                setIsScan(true);
                setData(null);
                //* Temporary store ticket code to prevent double scan
                lastScannedTicket = scanValue;
                // kalau pernah ada, clear dulu yang lama
                if (timerClearLastScannedTicket) {
                    clearTimeout(timerClearLastScannedTicket);
                }
                timerClearLastScannedTicket = setTimeout(() => {
                    lastScannedTicket = null;
                }, 20000);
                // khusus untuk yang menggunakan db payment
                if (
                    scanValue.startsWith("PY-") ||
                    scanValue.startsWith("py-")
                ) {
                    axios
                        .get(
                            `${process.env.REACT_APP_API2_URL}/me/booking/${scanValue}/check`,
                            {
                                headers: headers,
                                params: {
                                    exclusive: exclusive,
                                },
                            }
                        )
                        .then((res) => {
                            const booking = res.data.results;
                            setData(booking);
                            setTicket(booking.quantity);
                        })
                        .catch((err) => {
                            lastScannedTicket = null; // dikosongkan lagi bila error, supaya bisa scan ulang
                            if (err.response) {
                                Toast.fire({
                                    icon: "error",
                                    title: `${err.response.data.message}`,
                                });
                            }
                            setIsScan(false);
                        });
                } else {
                    axios({
                        method: "GET",
                        url:
                            process.env.REACT_APP_API_URL +
                            "operator/check?booking_code=" +
                            scanValue,
                        headers,
                    })
                        .then((res) => {
                            if (res.data.success) {
                                setData(res.data.data);
                                setTicket(res.data.data.quantity);
                                // if (res.data.data.sub_event.id === eventId) {
                                //     setData(res.data.data);
                                //     setTicket(res.data.data.quantity);
                                // } else {
                                //     Toast.fire({
                                //         icon: "warning",
                                //         title: "Tidak sesuai jadwal / ruangan yang dipilih!",
                                //     });
                                //     setIsScan(false);
                                // }
                            } else {
                                Toast.fire({
                                    icon: "warning",
                                    title: "Kode Salah, Silahkan Scan Ulang!",
                                });
                                setIsScan(false);
                            }
                        })
                        .catch((err) => {
                            lastScannedTicket = null; // dikosongkan lagi bila error, supaya bisa scan ulang
                            if (err.response) {
                                Toast.fire({
                                    icon: "error",
                                    title: `${err.response.data.message}`,
                                });
                            }
                            setIsScan(false);
                        });
                }
            }
        },
        [isScan]
    );

    const messageHandler = useCallback(
        (ev) => {
            const message = ev.data;
            if (message.startsWith("SCAN_VALUE:")) {
                if (!isScan) {
                    const scanValue = message.replace("SCAN_VALUE:", "");
                    handleScan(scanValue);
                }
            }
        },
        [isScan, handleScan]
    );

    const renderTicketButton = (ticketData) => {
        return (
            <div style={ticketConfirmStyle}>
                {Array.from(Array(ticketData.quantity), (e, i) => {
                    return (
                        <input
                            key={i}
                            type="button"
                            value={i + 1}
                            className={
                                "btn btn-ticket-qty " +
                                (ticket === i + 1 ? "" : "lowlight") +
                                " " +
                                ((i + 1) % 3 === 0
                                    ? "btn-warning"
                                    : (i + 1) % 2 === 0
                                    ? "btn-primary"
                                    : "btn-danger")
                            }
                            style={ticketButtonStyle}
                            onClick={(e) => setTicket(i + 1)}
                        />
                    );
                })}
            </div>
        );
    };

    const renderFooter = (event) => {
        return (
            <div className="fixed-bottom" style={footerScannerStyle}>
                <p style={{ marginBottom: 0, fontWeight: "bold" }}>
                    {event.main_event.name} - {event.name}
                </p>
                <p style={{ marginBottom: 0 }}>{event.room.name}</p>
            </div>
        );
    };

    useEffect(() => {
        let intervalMessageChannel = setInterval(() => {
            if (window.port) {
                // clear interval agar tidak perlu cek ulang
                clearInterval(intervalMessageChannel);
                showScanner();
                // setup listener
                window.port.onmessage = messageHandler;
            }
        }, 500);
        headers = {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.getItem("operator_token"),
        };
        if (!isRereg) {
            // ambil detail event bila tipe bukan rereg
            axios({
                method: "GET",
                url: `${process.env.REACT_APP_API_URL}operator/eventDetails?event_code=${params.code}&sub_event_id=${params.id}`,
                headers,
            })
                .then((res) => {
                    if (res.data.success) {
                        setEvent(res.data.data);
                        // ambil history event
                        getScanLogs(params.id).then((logs) =>
                            setScanLogs(logs)
                        );
                    } else {
                        setAlertShowModal(true);
                        setAlertMessage(
                            "Tidak ada informasi Event yang sedang berlangsung"
                        );
                    }
                })
                .catch((res) => {
                    setAlertShowModal(true);
                    setAlertMessage("Tidak ada Event yang sedang berlangsung");
                });
        } else {
            getScanLogs(params.id, exclusive).then((logs) => setScanLogs(logs));
        }
    }, [messageHandler, params, isRereg, exclusive]);

    const onSubmit = useCallback(() => {
        if (!isSubmitting) {
            setIsSubmitting(true);
            const payload = {
                booking_code: data.code,
                quantity: ticket,
            };
            // isi comment dengan operator_church_id hanya bila ada
            if (localStorage.getItem("operator_church_id")) {
                payload.comment = localStorage.getItem("operator_church_id");
            }
            if (data.code.startsWith("PY-")) {
                axios({
                    headers,
                    method: "POST",
                    url:
                        process.env.REACT_APP_API2_URL + "/me/booking/checkout",
                    params: {
                        exclusive: exclusive,
                    },
                    data: payload,
                })
                    .then((res) => {
                        setData(null);
                        Toast.fire({
                            icon: "success",
                            title: "Proses Checkout Berhasil",
                        });
                        // bila berhasil, refresh log scan
                        getScanLogs(params.id, exclusive).then((logs) =>
                            setScanLogs(logs)
                        );
                    })
                    .catch((err) => {
                        Toast.fire({
                            icon: "error",
                            title: `${err.response.data.message}`,
                        });
                    })
                    .finally(() => {
                        setIsSubmitting(false);
                        setIsScan(false);
                    });
            } else {
                axios({
                    headers,
                    method: "POST",
                    url: process.env.REACT_APP_API_URL + "operator/checkout",
                    data: payload,
                })
                    .then((res) => {
                        setData(null);
                        if (!res.data.success) {
                            Toast.fire({
                                icon: "warning",
                                title: "Terjadi kesalahan saat checkout",
                            });
                        } else {
                            Toast.fire({
                                icon: "success",
                                title: "Proses Checkout Berhasil",
                            });
                            // bila berhasil, refresh log scan
                            getScanLogs(params.id).then((logs) =>
                                setScanLogs(logs)
                            );
                        }
                    })
                    .catch((err) => {
                        Toast.fire({
                            icon: "error",
                            title: `${err.response.data.message}`,
                        });
                    })
                    .finally(() => {
                        setIsSubmitting(false);
                        setIsScan(false);
                    });
            }
        }
    }, [isSubmitting, ticket, data, params]);

    // handler memunculkan dan menghilangkan scanner
    useEffect(() => {
        if (isScan) {
            hideScanner();
        } else {
            // showScanner return boolean to flag request processed or not
            if (showScanner()) {
                // setup listener
                window.port.onmessage = messageHandler;
            }
        }
    }, [isScan, messageHandler]);

    // handler bila ada perubahan di variable data
    useEffect(() => {
        if (data != null) {
            /// auto submit sementara di non-aktifkan
            // // bila ticket yang di scan hanya 1 saja, langsung set checkout
            // const qty = data.quantity;
            // if (qty === 1) {
            //     onSubmit();
            // }
        }
    }, [data, onSubmit]);

    const alertHandleClose = () => {
        setAlertShowModal(false);
    };

    if (isRereg || event.name) {
        if (data !== null) {
            return (
                <>
                    <p style={headerStyle}>
                        <div
                            className="ticket-block"
                            style={{
                                backgroundColor: `#${data.sub_event.ticket_color_hex}`,
                            }}
                        ></div>
                        {data.sub_event.name}
                        {!isRereg && ", " + getTime2(data.sub_event.start_date)}
                        <div>{data.sub_event.room.name}</div>
                    </p>
                    {/* {!isRereg && (
                        <p style={bookingStyle}>{data.sub_event.room.name}</p>
                    )} */}
                    <p style={bookingStyle}>
                        {data.participant.name}, <b>{data.quantity}</b> Tiket{" "}
                        {renderPaidStatus(data.paid_status)}
                    </p>
                    {renderExtras(data.extras)}
                    <p style={confirmStyle}>Jumlah tiket yang akan diproses</p>
                    {renderTicketButton(data)}
                    <input
                        type="button"
                        className="btn btn-success"
                        style={submitStyle}
                        value={isSubmitting ? "Submiting..." : "Submit"}
                        onClick={onSubmit}
                        disabled={isSubmitting ? true : false}
                    />
                </>
            );
        } else {
            return (
                <>
                    <div className="container-fluid no-padding">
                        <p style={infoStyle}>Scan QR Code dengan Kamera Anda</p>
                    </div>
                    {process.env.REACT_APP_DEBUG == "true" && (
                        <div>
                            <input
                                type="text"
                                className="input w-100 "
                                onChange={(e) => handleScan(e.target.value)}
                            />
                        </div>
                    )}

                    {renderHistories()}
                    {renderActions()}
                    {!isRereg && renderFooter(event)}

                    {/* Alert Modal */}
                    <Modal show={alertShowModal} onHide={alertHandleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title style={modalAlertTitleStyle}>
                                Peringatan
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p style={modalAlertStyle}>{alertMessage}</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={alertHandleClose}>OK</Button>
                        </Modal.Footer>
                    </Modal>
                </>
            );
        }
    } else {
        return null;
    }
}

//Styles
const footerScannerStyle = {
    backgroundColor: "#1d2434",
    color: "#fff",
    textAlign: "center",
    padding: "7px",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
};

const ticketButtonStyle = {
    padding: "2px 8px",
    marginRight: "8px",
    flex: "1",
    fontSize: "1.5rem",
    paddingTop: "20px",
    paddingBottom: "20px",
};

const infoStyle = {
    marginTop: "30px",
    textAlign: "center",
};

const headerStyle = {
    marginLeft: "20px",
    marginRight: "20px",
    marginTop: "60px",
    marginBottom: "0px",
    fontWeight: "bold",
    fontSize: "1.2rem",
};

const bookingStyle = {
    marginLeft: "20px",
    marginRight: "20px",
    marginTop: "4px",
    fontSize: "1.0rem",
};

const confirmStyle = {
    marginLeft: "20px",
    marginRight: "20px",
    marginTop: "24px",
    fontSize: "1.0rem",
    marginBottom: "40px",
};

const ticketConfirmStyle = {
    marginLeft: "20px",
    marginRight: "20px",
    marginBottom: "40px",
    display: "flex",
};

const submitStyle = {
    width: "100%",
    position: "absolute",
    bottom: "0",
    paddingTop: "20px",
    paddingBottom: "20px",
    textTransform: "uppercase",
    fontSize: "1.3rem",
};

const modalAlertTitleStyle = {
    color: "#555",
    fontSize: "0.75rem",
    fontWeight: "bold",
};

const modalAlertStyle = {
    fontSize: "0.8rem",
};

export default OperatorScan;
