import React, { Component } from "react";
import PropTypes from "prop-types";
import { getDate } from "../../common/helpers";
import { Card } from "react-bootstrap";

class OperatorEventRereg extends Component {
    render() {
        if (this.props.event.sub_event) {
            const event = this.props.event;
            if (event.ticket_type === "food") {
                // Mengurutkan sub_event berdasarkan start_date
                const sortedSubEvents = [...this.props.event.sub_event].sort(
                    (a, b) => new Date(a.start_date) - new Date(b.start_date)
                );

                // Mengelompokkan sub_event yang sudah diurutkan berdasarkan tanggal
                const groupedSubEvents = sortedSubEvents.reduce(
                    (acc, subEvent) => {
                        const date = subEvent.start_date.split("T")[0];
                        if (!acc[date]) acc[date] = [];
                        acc[date].push(subEvent);
                        return acc;
                    },
                    {}
                );

                return (
                    <>
                        {Object.keys(groupedSubEvents).map((date, i) => (
                            <Card style={cardStyle} key={i}>
                                <Card.Header style={cardHeaderStyle}>
                                    {event.name} - {getDate(date)}
                                </Card.Header>
                                <Card.Body>
                                    <div className="row">
                                        {groupedSubEvents[date].map(
                                            (subEvent, j) => (
                                                <div
                                                    className="col-6"
                                                    style={colStyle}
                                                    key={j}
                                                >
                                                    <a
                                                        href={`/operator/scan/${this.props.event.code}/${subEvent.id}?rereg=1&exclusive=${event.exclusive}`}
                                                        className="btn"
                                                        style={{
                                                            ...itemStyle,
                                                            border: `solid 5px #${subEvent.ticket_color_hex}`,
                                                        }}
                                                    >
                                                        <div style={nameStyle}>
                                                            {subEvent.name}
                                                        </div>
                                                    </a>
                                                </div>
                                            )
                                        )}
                                    </div>
                                </Card.Body>
                            </Card>
                        ))}
                    </>
                );
            } else {
                const subEvent = event.sub_event[0];
                const subEventIds = event.sub_event.map((o) => o.id).join(",");
                return (
                    <>
                        {this.props.event.uniqueDates.map((dates, i) => (
                            <Card style={cardStyle} key={i}>
                                <Card.Header style={cardHeaderStyle}>
                                    {getDate(dates.date)}
                                </Card.Header>
                                <Card.Body>
                                    <div className="row">
                                        <div className="col-6" style={colStyle}>
                                            <a
                                                href={`/operator/scan/${this.props.event.code}/${subEventIds}?rereg=1&exclusive=${event.exclusive}`}
                                                className="btn"
                                                style={{
                                                    ...itemStyle,
                                                    border: `solid 5px #${subEvent.ticket_color_hex}`,
                                                }}
                                            >
                                                <div style={nameStyle}>
                                                    {event.name}
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        ))}
                    </>
                );
            }
        } else {
            return null;
        }
    }
}

//PropTypes
OperatorEventRereg.propTypes = {
    event: PropTypes.object.isRequired,
};

//Style
const cardStyle = {
    marginBottom: "15px",
    backgroundColor: "#494d57",
    borderRadius: "10px",
};

const cardHeaderStyle = {
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    padding: "8px",
    backgroundColor: "#1d2434",
    color: "white",
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
};

// Styles
const itemStyle = {
    display: "block",
    marginBottom: "15px",
    paddingTop: "4px",
    paddingBottom: "4px",
    backgroundColor: "#1d2434",
    color: "white",
};

const colStyle = {
    paddingLeft: "8px",
    paddingRight: "8px",
};

const nameStyle = {
    height: "4rem",
    fontSize: "0.7rem",
    fontWeight: "bold",
    textTransform: "uppercase",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
};

export default OperatorEventRereg;
